<template>
  <div class="width-full notification flex flex-center overflow-hidden">
    <div>
      <img :src="noticeIcon" alt="" class="top_nav_logo" />
    </div>
    <div
      ref="notification"
      @mouseover="mouseover"
      @mouseout="mouseout"
      class="flex1 overflow-hidden m-l-5 white-space-nowrap"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Notification",
  data() {
    return {
      noticeIcon: require("@/assets/img/topNav/notice.png"),
      desc: "通知公告走马灯",
      box: null,
      setInterval: null,
      scrollWidth: 0,
      scrollLeft: 0,
      message:
        "通知公告内容通知公告内容通知公告内容通知公告内容通知公告内容通知公告内容通知公告内容通知公告内容通知公告内容通知公告内容通知公告内容通知公告内容通知公告内容通知公告内容通知公告内容通知公告内容"
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      setTimeout(() => {
        this.box = this.$refs.notification;
        if (this.box) {
          this.scrollWidth = this.box.scrollWidth - this.box.offsetWidth;
          this.setIntervalFn();
        }
      }, 3000);
    },
    mouseover() {
      clearInterval(this.setInterval);
    },
    mouseout() {
      this.setIntervalFn();
    },
    setIntervalFn() {
      if (!this.scrollWidth) {
        return;
      }
      if (this.setInterval) {
        clearInterval(this.setInterval);
      }
      this.setInterval = setInterval(() => {
        this.scrollLeft += 1;
        if (this.scrollLeft > this.scrollWidth) {
          this.scrollLeft = 0;
        }
        this.box.scrollLeft = this.scrollLeft;
      }, 30);
    }
  }
};
</script>

<style lang="scss" scoped>
.notification {
  line-height: 1em;
  .top_nav_logo {
    height: 18px;
    margin-right: 5px;
  }
}
</style>
