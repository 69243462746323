<template>
  <div class="client">
    <div class="client-top-nav">
      <TopNav />
    </div>
    <div class="client-content">
      <div class="client-content-left">
        <LeftNav />
      </div>
      <div class="client-content-right">
        <BreadCrumb type="client" class="border-radius" />
        <div class="flex1 overflow-hidden border-radius">
          <router-view v-if="!isKeepAlive"></router-view>
          <keep-alive>
            <router-view v-if="isKeepAlive"></router-view>
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from "@/components/TopNav";
import LeftNav from "@/components/LeftNav";
import BreadCrumb from "@/components/BreadCrumb";

export default {
  name: "Home",
  components: {
    TopNav,
    LeftNav,
    BreadCrumb
  },
  data() {
    return {
      desc: "首页"
    };
  },
  computed: {
    isKeepAlive() {
      return this.$route.meta && this.$route.meta.keepAlive;
    }
  }
};
</script>

<style lang="scss" scoped>
.client {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  min-height: 650px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .client-top-nav {
    height: 60px;
    background-color: #fcfcfc;
    box-sizing: border-box;
  }
  .client-content {
    flex: 1;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
  }
  .client-content-left {
    box-sizing: border-box;
  }
  .client-content-right {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: #f3f7fe;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .client-page {
      width: 100%;
      height: 100%;
      overflow: hidden;
      box-sizing: border-box;
      background-color: #ffffff;
    }
    .border-radius {
      border-radius: 4px;
    }
  }
}
</style>
