<template>
  <el-dialog
    title="账号管理"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    width="500px"
    @close="close"
  >
    <div class="content-box" v-loading="loading">
      <el-card v-for="(item, i) in accountsGroupsList" :key="i" class="m-b-10">
        <div class="flex">
          <div class="w-20">
            <el-radio v-if="selectAccount === i" v-model="selectAccount" :label="i"></el-radio>
            <span v-else @click="toggle($event, item)">
              <el-radio :label="i"></el-radio>
            </span>
          </div>
          <div class="flex1 m-l-10">
            <div class="flex flex-center">
              <span class="flex1">{{ item.userName }}</span>
              <el-button v-if="selectAccount !== i" plain size="mini" @click="removeAccount(item)">
                移除
              </el-button>
            </div>
            <div class="m-t-5">
              <span class="w-60">用户名：</span>
              <span>{{ item.userAccount }}</span>
            </div>
            <div class="m-t-5">
              <span class="w-60">角色：</span>
              <span>{{ item.role }}</span>
            </div>
            <div class="m-t-5">
              <span class="w-60">商城名称：</span>
              <span>{{ item.storeName }}</span>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <span slot="footer">
      <el-button type="primary" icon="el-icon-plus" plain size="mini" @click="addAccountManage">添加关联用户</el-button>
    </span>
    <AddAccount ref="addAccount" />
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import AddAccount from "../AddAccount";
import { deleteAccountsGroups, accountsQuicklyLogin } from "@/api/accounts";

export default {
  name: "AccountManage",
  components: {
    AddAccount
  },
  data() {
    return {
      desc: "账号管理",
      loading: false,
      dialogVisible: false,
      selectAccount: 0,
      accountsGroupsList: []
    };
  },
  computed: {
    ...mapState("user", {
      userInfo: state => {
        return state.userInfo;
      },
      accountsGroups: state => {
        return state.accountsGroups;
      }
    })
  },
  watch: {
    accountsGroups: {
      immediate: true,
      handler: function() {
        const list = JSON.parse(JSON.stringify(this.accountsGroups));
        list.map((item, i) => {
          if (this.userInfo.userId === item.userId) {
            this.selectAccount = i;
          }
        });
        this.accountsGroupsList = list;
      }
    }
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    addAccountManage() {
      this.$refs.addAccount.open();
    },
    toggle(e, item) {
      if (e.target.tagName === "SPAN") {
        this.$alert(`是否切换到【${item.userName}】账号登录？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          showCancelButton: true,
          callback: res => {
            if (res === "confirm") {
              const data = {
                id: item.id
              };
              this.loading = true;
              accountsQuicklyLogin(data)
                .then(() => {
                  this.loading = false;
                  this.$message.success("切换成功");
                  setTimeout(() => {
                    window.location.href = "/";
                  }, 500);
                })
                .catch(err => {
                  this.loading = false;
                  this.$message.error(err.message || "切换失败！");
                });
            }
          }
        });
      }
    },
    removeAccount(item) {
      this.$alert(`确定移除此用户的关联吗？移除后将不能便捷登录。`, "提示", {
        confirmButtonText: "继续移除",
        cancelButtonText: "取消",
        showCancelButton: true,
        callback: res => {
          if (res === "confirm") {
            const data = {
              ids: [item.id]
            };
            this.loading = true;
            deleteAccountsGroups(data)
              .then(() => {
                this.loading = false;
                this.$message.success({ message: "移除成功", showClose: true });
                // 获取账号组列表
                this.$store.dispatch("user/getAccountsGroups");
              })
              .catch(err => {
                this.loading = false;
                this.$message.error(err.message || "移除失败！");
              });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .content-box {
  .el-radio__inner {
    width: 16px;
    height: 16px;
  }
  .el-radio__inner::after {
    width: 6px;
    height: 6px;
  }
  .el-radio__label {
    display: none;
  }
}
</style>
