import { phoneValidate } from "@/utils/validate";
import { getAccountsList } from "@/api/base";

/**
 * 通过手机号精确匹配获取账号
 */
export async function loadAccountList(phone) {
  let accountList = [];
  if (phoneValidate(phone)) {
    const data = await getAccountsList({ phone: phone });
    if (data && data.accounts) {
      accountList = data.accounts || [];
    }
  }
  return accountList;
}
