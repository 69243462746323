<template>
  <el-dialog
    title="添加关联账号"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    width="500px"
    @close="close"
    v-if="dialogVisible"
  >
    <div class="content-box">
      <el-form ref="form" :model="formData" :rules="rules" label-width="130px">
        <el-form-item label="手机号/用户名：" prop="phone">
          <el-input
            v-model="formData.phone"
            clearable
            placeholder="请输入手机号/用户名"
            :readonly="readonly"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="accountList.length > 0" label="用户名：" prop="account">
          <el-select class="width-full" v-model="formData.account" placeholder="请选择账号">
            <el-option v-for="item in accountList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录密码：" prop="password">
          <PassWord
            v-model="formData.password"
            :password="formData.password"
            :is-message="false"
            :readonly="readonly"
          />
        </el-form-item>
      </el-form>
      <div class="text-right">
        <el-button @click="forgetPassword" type="text">忘记密码？</el-button>
      </div>
    </div>
    <span slot="footer">
      <el-button v-loading="loading" class="width-full" type="primary" icon="el-icon-plus" @click="confirm">
        确定添加
      </el-button>
    </span>
    <ForgetPassword ref="forgetPassword" />
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import PassWord from "@/components/PassWord";
import ForgetPassword from "@/components/ForgetPassword";
import { loadAccountList } from "@/common/baseData";
import { passwordValidate, passwordValidateTips } from "@/utils/validate";
import { createAccountsGroups } from "@/api/accounts";

export default {
  name: "AddAccount",
  components: {
    PassWord,
    ForgetPassword
  },
  data() {
    const validatorAccount = (rule, value, callback) => {
      if (this.accountList.length > 1) {
        if (this.formData.account) {
          callback();
        } else {
          callback(new Error("请选择账号"));
        }
      } else {
        callback();
      }
    };
    const validatorPassword = (rule, value, callback) => {
      if (!passwordValidate(value)) {
        callback(new Error(passwordValidateTips));
      } else {
        callback();
      }
    };
    return {
      desc: "添加账号管理",
      loading: false,
      dialogVisible: false,
      readonly: true,
      rules: {
        phone: [{ message: "请输入手机号/用户名", trigger: "blur", required: true }],
        account: [{ validator: validatorAccount, trigger: "blur", required: true }],
        password: [{ validator: validatorPassword, trigger: "blur", required: true }]
      },
      formData: {
        phone: "",
        account: "",
        password: ""
      },
      accountList: []
    };
  },
  computed: {
    ...mapState("user", {
      userInfo: state => {
        return state.userInfo;
      }
    })
  },
  watch: {
    "formData.phone"() {
      this.getAccountList();
    }
  },
  methods: {
    open() {
      this.dialogVisible = true;
      setTimeout(() => {
        this.readonly = false;
      }, 1000);
    },
    close() {
      this.dialogVisible = false;
      this.readonly = true;
      this.formData.phone = "";
      this.formData.account = "";
      this.formData.password = "";
      this.formData.accountList = [];
    },
    confirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            // 用户账号（用户名）/手机号，因为无法区分账号和手机号，所以共用一个字段
            account: this.formData.account || this.formData.phone,
            password: this.formData.password
          };
          this.loading = true;
          createAccountsGroups(data)
            .then(() => {
              this.loading = false;
              // 获取账号组列表
              this.$store.dispatch("user/getAccountsGroups");
              this.close();
            })
            .catch(err => {
              this.loading = false;
              this.$message.error(err.message || "添加失败！");
            });
        }
      });
    },
    async getAccountList() {
      const list = await loadAccountList(this.formData.phone);
      this.accountList = list.filter(item => item !== this.userInfo.account);
      if (this.accountList.length === 1) {
        this.formData.account = this.accountList[0];
      } else {
        this.formData.account = "";
      }
    },
    forgetPassword() {
      this.$refs.forgetPassword.open();
    }
  }
};
</script>
