<template>
  <el-dialog
    title="忘记密码"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    width="500px"
    @close="close"
    v-if="dialogVisible"
  >
    <div class="content-box">
      <div class="text-warning m-b-20">
        若您的用户名未绑定手机号或其他原因，请联系账套管理员处理。
      </div>
      <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="formData.phone" clearable placeholder="请输入手机号" :readonly="readonly"></el-input>
        </el-form-item>
        <el-form-item v-if="accountList.length > 0" label="用户名：" prop="account">
          <el-select class="width-full" v-model="formData.account" placeholder="请选择账号">
            <el-option v-for="item in accountList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="验证码：" prop="smsCaptcha">
          <SMSCode
            v-model="formData.smsCaptcha"
            :smsCaptcha="formData.smsCaptcha"
            :phone="formData.phone"
            type="resetPassword"
          />
        </el-form-item>
        <el-form-item label="登录密码：" prop="newPassword">
          <PassWord
            v-model="formData.newPassword"
            :password="formData.newPassword"
            :is-message="false"
            :readonly="readonly"
            placeholder="请设定新登录密码 (8-16位数字或字母)"
          />
        </el-form-item>
        <el-form-item label="确认密码：" prop="confirmPassword">
          <PassWord
            v-model="formData.confirmPassword"
            :password="formData.confirmPassword"
            :is-message="false"
            :readonly="readonly"
            placeholder="再次输入新登录密码"
          />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button v-loading="loading" class="width-full" type="primary" @click="confirm">
        确认
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import SMSCode from "@/components/SMSCode";
import PassWord from "@/components/PassWord";
import { loadAccountList } from "@/common/baseData";
import { passwordValidate, passwordValidateTips } from "@/utils/validate";
import { checkPhone, updateResetPassword } from "@/api/base";

export default {
  name: "ForgetPassword",
  components: {
    SMSCode,
    PassWord
  },
  data() {
    const validatorAccount = (rule, value, callback) => {
      if (this.accountList.length > 1) {
        if (this.formData.account) {
          callback();
        } else {
          callback(new Error("请选择账号"));
        }
      } else {
        callback();
      }
    };
    const validatorSmsCaptcha = (rule, value, callback) => {
      if (value) {
        if (value.length === 6) {
          callback();
        } else {
          callback(new Error("验证码位数不正确"));
        }
      } else {
        callback(new Error("请输入手机验证码"));
      }
    };
    const validatorNewPassword = (rule, value, callback) => {
      if (!passwordValidate(value)) {
        callback(new Error(passwordValidateTips));
      } else {
        callback();
      }
    };
    const validatorConfirmPassword = (rule, value, callback) => {
      if (!passwordValidate(value)) {
        callback(new Error(passwordValidateTips));
      } else {
        if (this.formData.newPassword === value) {
          callback();
        } else {
          callback(new Error("两次密码不一致"));
        }
      }
    };
    return {
      desc: "添加账号管理-修改密码",
      loading: false,
      dialogVisible: false,
      readonly: true,
      rules: {
        phone: [{ message: "请输入手机号/用户名", trigger: "blur", required: true }],
        account: [{ validator: validatorAccount, trigger: "blur", required: true }],
        smsCaptcha: [{ validator: validatorSmsCaptcha, trigger: "blur", required: true }],
        newPassword: [{ validator: validatorNewPassword, trigger: "blur", required: true }],
        confirmPassword: [{ validator: validatorConfirmPassword, trigger: "blur", required: true }]
      },
      formData: {
        phone: "",
        account: "",
        smsCaptcha: "",
        newPassword: "",
        confirmPassword: ""
      },
      accountList: []
    };
  },
  computed: {
    ...mapState("user", {
      userInfo: state => {
        return state.userInfo;
      }
    })
  },
  watch: {
    "formData.phone"() {
      this.getAccountList();
    }
  },
  methods: {
    open() {
      this.dialogVisible = true;
      setTimeout(() => {
        this.readonly = false;
      }, 1000);
    },
    close() {
      this.dialogVisible = false;
      this.readonly = true;
      this.formData.phone = "";
      this.formData.account = "";
      this.formData.smsCaptcha = "";
      this.formData.password = "";
      this.formData.accountList = [];
    },
    confirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data1 = {
            phone: this.formData.phone,
            smsCaptcha: this.formData.smsCaptcha
          };
          this.loading = true;
          checkPhone(data1)
            .then(() => {
              this.loading = false;
              const data2 = {
                phone: this.formData.phone, // 手机号
                account: this.formData.account, //  账号
                smsCaptcha: this.formData.smsCaptcha, //  短信验证码
                newPassword: this.formData.newPassword, //  新密码
                confirmPassword: this.formData.confirmPassword //  新密码
              };
              this.loading = true;
              updateResetPassword(data2)
                .then(() => {
                  this.loading = false;
                  this.$message.success("密码修改成功");
                  this.close();
                })
                .catch(err => {
                  this.loading = false;
                  this.$message.error(err.message || "修改失败！");
                });
            })
            .catch(err => {
              this.loading = false;
              this.$message.error(err.message || "身份认证失败！");
            });
        }
      });
    },
    async getAccountList() {
      const list = await loadAccountList(this.formData.phone);
      this.accountList = list.filter(item => item !== this.userInfo.account);
      if (this.accountList.length === 1) {
        this.formData.account = this.accountList[0];
      } else {
        this.formData.account = "";
      }
    }
  }
};
</script>
