<template>
  <div class="left-nav" :style="leftNavStyle">
    <div class="left-nav-title flex flex-center box-sizing p-a-10 white-space-nowrap">
      <span v-if="!isCollapse" class="font-size-16 flex1">菜单栏</span>
      <span v-if="isCollapse" @click="navFold" class="el-icon-s-unfold font-size-24 cursor-pointer"></span>
      <span v-else @click="navFold" class="el-icon-s-fold font-size-24 cursor-pointer"></span>
    </div>
    <el-menu
      class="left-nav-menu"
      :class="isCollapse ? 'left-nav-collapse' : ''"
      :collapse="isCollapse"
      :default-active="defaultActive"
      :default-openeds="defaultOpends"
    >
      <NavItem v-for="item in navList" :key="item.path" :item="item" />
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import children from "@/router/children";
import NavItem from "./components/NavItem";

export default {
  name: "ClientLeftNav",
  components: {
    NavItem
  },
  data() {
    return {
      desc: "左侧导航",
      isCollapse: false,
      defaultActive: "",
      defaultOpends: [],
      navList: children
    };
  },
  computed: {
    ...mapGetters("user", ["permissions", "userInfo"]),
    leftNavStyle() {
      return this.isCollapse ? "width: 45px" : "width: 145px";
    }
  },
  watch: {
    "$route.path"() {
      this.defaultActive = this.$route.path;
      this.defaultOpends = this.$route.matched.map(item => item.path);
    },
    permissions() {
      this.navList = this.setPermissions(children);
      this.navList = window.tool.deepCloneRecursive(this.navList);
    },
    userInfo: {
      deep: true,
      handler: function() {
        this.navList = this.setPermissions(children);
        this.navList = window.tool.deepCloneRecursive(this.navList);
      }
    }
  },
  created() {
    this.defaultActive = this.$route.path;
  },
  methods: {
    setPermissions(list) {
      for (const item of list) {
        // 控制显示 对账单|企业资料|员工管理
        if (item.name === "对账中心") {
          item.isHide = !this.permissions.others.statement;
        }
        if (item.name === "员工管理") {
          item.isHide = !(this.permissions.others.staffs && this.userInfo.isCompany);
        }
        if (item.children && item.children.length) {
          item.children = this.setPermissions(item.children);
        }
      }
      return list;
    },
    navFold() {
      this.isCollapse = !this.isCollapse;
    }
  }
};
</script>

<style lang="scss" scoped>
.left-nav {
  height: 100%;
  transition: all 0.3s;
  box-shadow: 0 0 20px #e6e7ea;
  display: flex;
  flex-direction: column;
  .left-nav-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .left-nav-menu {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  /deep/ .el-menu {
    border-right: 0;
    .el-submenu__title,
    .el-menu-item {
      height: 48px;
      line-height: 48px;
      i {
        font-size: 20px;
      }
      .el-submenu__icon-arrow {
        font-size: 12px;
      }
    }
    .el-submenu__title:hover,
    .el-menu-item:hover,
    .el-menu-item.is-active:hover {
      color: #ffffff;
      background-color: #6589f8;
      i {
        color: #ffffff;
      }
    }
    .el-menu-item.is-active {
      color: #ffffff;
      background-color: #6589f8;
    }
  }
  /deep/ .el-menu--collapse {
    width: auto;
    .el-submenu {
      .el-submenu__title {
        span,
        .el-submenu__icon-arrow {
          display: none !important;
        }
      }
    }
  }
  /deep/ .el-submenu {
    .el-submenu__title {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
  .left-nav-collapse {
    /deep/ .el-menu-item {
      .el-tooltip {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
  }
}
</style>
