<template>
  <div class="breadcrumb">
    <el-breadcrumb v-if="type === 'client'" separator-class="el-icon-arrow-right">
      <template v-for="(v, i) in routeList">
        <el-breadcrumb-item v-if="i > 0" :to="v.path" :key="v.path">{{ v.name }}</el-breadcrumb-item>
      </template>
    </el-breadcrumb>
    <el-breadcrumb v-else separator-class="el-icon-arrow-right">
      <template v-for="v in routeList">
        <el-breadcrumb-item :to="v.path" :key="v.path">{{ v.name }}</el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  props: {
    type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      routeList: []
    };
  },
  watch: {
    "$route.matched": {
      handler(val) {
        if (val && val.length && ["订单详情", "订单支付"].includes(val[val.length - 1].name)) {
          let obj = {
            path: "/order/order-list",
            name: "订单列表"
          };
          this.routeList = [...val];
          this.routeList.splice(val.length - 1, 0, obj);
        } else if (val && val.length && ["对账单详情", "对账单支付"].includes(val[val.length - 1].name)) {
          let obj = {
            path: "/reconciliations/currentReconciliation",
            name: "往来对账"
          };
          this.routeList = [...val];
          this.routeList.splice(val.length - 1, 0, obj);
        } else {
          this.routeList = [...val];
        }
      },
      immediate: true
    }
  }
};
</script>

<style lang="less" scoped>
.breadcrumb {
  padding: 10px;
  background: #ffffff;
  margin-bottom: 8px;
  /deep/.el-breadcrumb__inner.is-link {
    color: @text-color-hint;
  }
  /deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: @text-color-base;
  }
}
</style>
