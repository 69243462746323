<template>
  <div v-if="!item.isHide">
    <el-menu-item
      v-if="!item.children || item.isSingle"
      :index="item.path"
      :class="[isActive(item.path, item.redirect) ? 'is-active' : '', item.isSingle]"
      @click="navClick(item)"
      :style="{ padding: item.isSingle ? '0 10px' : '0 20px' }"
    >
      <i :class="item.icon" v-if="item.icon"></i>
      <span slot="title">{{ item.name }}</span>
    </el-menu-item>
    <el-submenu v-else :index="item.path">
      <template slot="title">
        <i :class="item.icon"></i>
        <span>{{ item.name }}</span>
      </template>
      <NavItem v-for="child in item.children" :key="child.path" :item="child" />
    </el-submenu>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "NavItem",
  props: ["item"],
  data() {
    return {
      desc: "左侧导航"
    };
  },
  computed: {
    ...mapGetters("companyInfo", ["noLoginLook"]),
    ...mapState("user", {
      userInfo: state => {
        return state.userInfo;
      }
    })
  },
  methods: {
    isActive(path, redirect) {
      return this.$route.path === path || this.$route.path === redirect;
    },
    async navClick(item) {
      if (this.noLoginLook && item.path !== "/products") {
        this.$router.push({
          path: "/login"
        });
        return;
      }
      // 去浏览商品页面
      if (["/products"].includes(item.path)) {
        if (this.userInfo.auditStatus !== "审核通过" && localStorage.isLogin === "true") {
          this.$store.dispatch("user/getUserInfo");
        }
      }
      // 订单列表页面与关联思锐ERP页面
      if (["/order/order-list", "/manage/associated"].includes(item.path)) {
        if (this.userInfo.auditStatus !== "审核通过" && localStorage.isLogin === "true") {
          const data = await this.$store.dispatch("user/getUserInfo");
          if (data && data.auditStatus !== "审核通过") {
            this.$bus.$emit("showAccountStatusDialog");
            return;
          }
        }
      }
      this.$router.push({
        path: item.path
      });
    }
  }
};
</script>

<style scoped></style>
